import React from "react"
import { graphql } from "gatsby";

import Footer from "../components/common/footer";

import Landing from "../components/about/landing";
import About from "../components/about/about";
import Interests from "../components/about/interests";
// import Testimonials from "../components/about/testimonials";
import LatestPosts from "../components/about/latestPosts";

import "./../styles/style.scss";


class IndexPage extends React.Component {

    render() {
        const { data } = this.props;
        const posts = data.allMarkdownRemark.edges;

        return (
            <div>
                <Landing />

                <About />
                <Interests />
                {/* <Testimonials /> */}
                <LatestPosts posts={posts} />

                <Footer />
            </div>
        )
    }
}

export default IndexPage;


export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
    allMarkdownRemark(limit: 3, sort: {fields: [frontmatter___date], order: DESC }) {
        edges {
            node {
                id
                excerpt(pruneLength: 100)
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    title
                    tags
                    author
                    description
                    featuredImage {
                        id
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
            }
        }
    }
}
`
