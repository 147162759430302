import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import CardGrid from "../grid/card-grid";


class LatestPosts extends React.Component {
    render() {
        var posts = this.props.posts;

        return (
            <section id="latest-posts" className={"section-padding"}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={"section-title"}>
                                <h2><span>Latest Posts</span></h2>
                                {/* <p>Checkout what I have been up to!</p> */}
                            </div>
                        </Col>
                    </Row>

                    <CardGrid posts={posts} postsPerPage={3} />
                </Container>
            </section>
        )
    }
}

export default LatestPosts;
