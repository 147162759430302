import React from "react"
import { Row, Col, Container } from "react-bootstrap";

class About extends React.Component {
    render() {
        return (
            <section id="about">
                <div>
                    <Container className={"fill-bg"}>
                        <Row>
                            <Col md={12}>
                                <div className={"about"}>
                                    {/* <div className={"section-title"}>
                                        <h2>About</h2>
                                    </div> */}

                                    <p className={"description"}>Growing up in an area where technological advances and innovations were met with apprehension, it was difficult to picture the state of development this field would reach. I have utilized the passion that sparked in me at such a young age to help business build their software and unlock their data’s full potential by leveraging cutting-edge technologies.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        )
    }
}

export default About;
