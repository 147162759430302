import React from "react"
import { Row, Col, Container } from "react-bootstrap";

class Interests extends React.Component {
    render() {
        return (
            <section id="skills" className={"skills"}>
                <Container>
                    <Row>
                        <Col md={4} sm={12} xs={12} className={"skill-card skill-card-left"}>
                            <span><i className={"fas fa-hdd"}></i></span>
                            <h1>DevOps</h1>
                            <p>Making sure applications have strong building blocks.</p>

                            <ul>
                                <li>Infrastructure as Code</li>
                                <li>Containerization</li>
                                <li>CI/CD</li>
                            </ul>
                        </Col>
                        <Col md={4} sm={12} xs={12} className={"skill-card skill-card-middle"}>
                            <span><i className={"fas fa-code"}></i></span>
                            <h1>Engineering</h1>
                            <p>Turning cups of coffee into production ready code.</p>

                            <ul>
                                <li>Web Applications</li>
                                <li>ETL (Extract, Transform, Load) pipelines</li>
                                {/* <li>Mockups</li> */}
                            </ul>
                        </Col>
                        <Col md={4} sm={12} xs={12} className={"skill-card skill-card-right"}>
                            <span><i className={"fas fa-brain"}></i></span>
                            <h1>Data Science</h1>
                            <p>Munching on data and paving the way to smarter systems.</p>

                            <ul>
                                <li>Deep Learning</li>
                                <li>Data Visualization and Analysis</li>
                                <li>Recommender Systems</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Interests;
