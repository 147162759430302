import React from "react";
import { Col, Container } from "react-bootstrap";
import Seo from "../common/SEO";

import Typing from 'react-typing-animation';

class Landing extends React.Component {
    render() {
        return (
            <div>
                <Seo pageName={this.props.pageName} />
                <Container className={"landing"}>
                    <Col md={12} className={"landing-vertical"}>
                        <div>
                            <span>Hello world!</span><span className={"landing-im"}> I'm</span>

                            <Typing
                                startDelay={5}
                                speed={70}
                                className={"landing-name"}
                                hideCursor={true}
                            >
                                Mohamad Hallak
                            </Typing>
                        </div>
                    </Col>
                </Container>
            </div>
        )
    }
}

export default Landing;
